<script setup lang="ts">
import Icon from '@design-system/components/Icon/Icon.vue'
import type { PropType } from 'vue'
import { type FileRejectReason, useDropzone } from 'vue3-dropzone'
import { useFiles, useInnerValue, useProps, useQueue, useUpload } from './composables'
import { LDropzoneStatus } from './constants'
import type { LDropzoneContextProp } from './types'

const props = defineProps({
  context: {
    type: Object as PropType<LDropzoneContextProp>,
    required: true,
  },
})

// PROPS
const { maxFiles, maxSize, multiple, parallelUploads, uploadMultiple, acceptedFiles, disabled, attachmentType } =
  useProps(props)
// INNER VALUE
const innerValue = useInnerValue(toRef(props, 'context'), multiple)
// COMPUTED
const isEmpty = computed(() => innerValue.value.length === 0)

// DROPZONE
const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
  accept: acceptedFiles.value,
  maxFiles: maxFiles.value,
  maxSize: maxSize.value,
  multiple: multiple.value,
  disabled: disabled.value,
  onDrop: async (acceptFiles: File[], _rejectReasons: FileRejectReason[]) => {
    for (const file of acceptFiles) {
      await addFile(file)
    }
  },
  noClick: true,
})
// Dropzone click event handler
const onClickDropzone = () => {
  // Disable dropzone click event if it has files.
  if (!disabled.value && isEmpty.value) {
    open?.()
  }
}

// FILES
const { canRemoveAll, isRemovingFiles, addFile, removeFile, removeAllFiles, canBeRemoved } = useFiles(innerValue, {
  onAdd: (file) => enqueueFile(file),
})

// QUEUE
const { queue, enqueueFile, processQueue } = useQueue(innerValue, {
  parallelUploads: parallelUploads.value,
  uploadMultiple: uploadMultiple.value,
  upload: (files) => upload(files),
})

// UPLOAD
const { upload } = useUpload({
  queue,
  processQueue: () => processQueue(),
  attachmentType: attachmentType.value,
  onError: () => {
    props.context?.node.setErrors(['File has failed to upload'])
  },
})

// EXPOSE
// Add queue `onIdle` func to FormKit context
// eslint-disable-next-line vue/no-mutating-props
props.context.onIdle = () => queue.onIdle()
// eslint-disable-next-line vue/no-mutating-props
props.context.removeAllFiles = () => removeAllFiles()
</script>

<template>
  <div>
    <div v-bind="getRootProps()">
      <input :id="props.context.id" v-bind="getInputProps()" :disabled="disabled" />

      <div
        v-if="isDragActive || isEmpty"
        class="formkit-disabled:cursor-default formkit-invalid:border-red-500 relative flex min-h-[150px] cursor-pointer items-center justify-center rounded-md border border-dashed border-gray-200 p-6 text-center"
        @click.prevent.stop="onClickDropzone"
      >
        <div>
          <span class="formkit-invalid:text-red-800 mb-2 block text-base font-semibold text-gray-800">
            {{ $t('general.dropFiles') }}
          </span>
          <div v-if="isEmpty">
            <span class="formkit-invalid:text-red-400 mb-2 block text-sm font-medium text-gray-400">
              {{ $t('general.or') }}
            </span>
            <span
              class="formkit-invalid:border-red-800 formkit-invalid:text-red-800 inline-flex rounded-lg border border-gray-200 px-5 py-2 text-sm font-medium text-gray-800"
            >
              {{ $t('general.browseFiles') }}
            </span>
          </div>
        </div>
      </div>

      <div
        v-else
        class="formkit-disabled:cursor-default formkit-invalid:border-red-500 relative space-y-2 rounded-md border border-dashed border-gray-200 p-2.5"
      >
        <!-- Files list -->
        <ul class="w-full space-y-2" :data-has-multiple="innerValue.length > 1">
          <!-- File item -->
          <li v-for="file in innerValue" :key="file._id" class="rounded-lg bg-gray-100 px-4 py-2">
            <div class="flex items-center">
              <!-- File name -->
              <Icon icon="file-list" class="mr-2" />
              <span class="mr-2 flex-auto text-sm" :class="{ 'text-red-500': file.status === LDropzoneStatus.ERROR }">
                {{ file.name }}
              </span>

              <LButton
                :class="{
                  'hover:!bg-white hover:ring-2 hover:ring-gray-200': canBeRemoved(file),
                }"
                variant="secondary"
                :rounded="true"
                size="xs"
                icon="close"
                :icon-size="20"
                :disabled="!canBeRemoved(file)"
                :loading="file.status === LDropzoneStatus.REMOVING"
                @click="removeFile(file)"
              />
            </div>

            <!-- Progress bar -->
            <div
              v-if="file.status === LDropzoneStatus.UPLOADING"
              class="relative mt-2 h-[6px] w-full rounded-lg bg-gray-300"
            >
              <div
                class="absolute left-0 right-0 h-full rounded-lg bg-green-500"
                :style="{ width: `${file.progress}%` }"
              />
            </div>
          </li>
        </ul>

        <!-- Action buttons -->
        <div v-if="multiple" class="flex items-center w-full">
          <!-- Add file -->
          <LButton variant="secondary" size="sm" class="mr-2" @click="open"> {{ $t('general.addFile') }} </LButton>

          <!-- Remove all files -->
          <LButton
            variant="secondary"
            size="sm"
            :disabled="!canRemoveAll"
            :loading="isRemovingFiles"
            @click="removeAllFiles"
          >
            {{ $t('general.removeAllFiles') }}
          </LButton>
        </div>
      </div>
    </div>
  </div>
</template>
