import revive_payload_client_uEYVSyGRiP from "/root/project/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.11.1_jiti@1.21.6__ioredis@5.4.1_o72ear2l3kedau5wvajjc4lyk4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_wSJjb5xUH4 from "/root/project/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.11.1_jiti@1.21.6__ioredis@5.4.1_o72ear2l3kedau5wvajjc4lyk4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_EBkMsVG4sK from "/root/project/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.11.1_jiti@1.21.6__ioredis@5.4.1_o72ear2l3kedau5wvajjc4lyk4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_KXqPRAvysp from "/root/project/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.11.1_jiti@1.21.6__ioredis@5.4.1_o72ear2l3kedau5wvajjc4lyk4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Nhd36xN4xS from "/root/project/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.11.1_jiti@1.21.6__ioredis@5.4.1_o72ear2l3kedau5wvajjc4lyk4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_WIZYXR03B8 from "/root/project/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.11.1_jiti@1.21.6__ioredis@5.4.1_o72ear2l3kedau5wvajjc4lyk4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_pOVEX5ZHM0 from "/root/project/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.11.1_jiti@1.21.6__ioredis@5.4.1_o72ear2l3kedau5wvajjc4lyk4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/root/project/.nuxt/components.plugin.mjs";
import prefetch_client_kSRMywzhhM from "/root/project/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.11.1_jiti@1.21.6__ioredis@5.4.1_o72ear2l3kedau5wvajjc4lyk4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_J0821rZplQ from "/root/project/src/modules/auth/infrastructure/keycloak/runtime/plugin.ts";
import _001_notivue_client_bE2FHKqHiu from "/root/project/.nuxt/001.notivue.client.mjs";
import plugin_6Ph1iQzNHk from "/root/project/node_modules/.pnpm/@formkit+auto-animate@0.8.2/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import formkitPlugin_pZqjah0RUG from "/root/project/.nuxt/formkitPlugin.mjs";
import plugin_cDYFCjuSwD from "/root/project/src/modules/shared/modules/dayjs/runtime/plugin.ts";
import plugin_5WFR5umNIc from "/root/project/node_modules/.pnpm/mixpanel-nuxt@0.0.12_magicast@0.3.4_rollup@4.20.0/node_modules/mixpanel-nuxt/dist/runtime/plugin.js";
import plugin_DVVyzEOKJp from "/root/project/src/modules/shared/modules/sentry/runtime/plugin.ts";
import switch_locale_path_ssr_e7GgmmIAzw from "/root/project/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_magicast@0.3.4_rollup@4.20.0_vue@3.4.37_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_C8A1HtGs20 from "/root/project/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_magicast@0.3.4_rollup@4.20.0_vue@3.4.37_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import auth_aEAofnvHks from "/root/project/src/modules/auth/plugins/auth.ts";
import chartjs_n0Xc8XinLa from "/root/project/src/modules/ui/plugins/chartjs.ts";
import echarts_sPDVA7Eyaf from "/root/project/src/modules/ui/plugins/echarts.ts";
import primevue_y8HUkHsSHD from "/root/project/src/modules/ui/plugins/primevue.ts";
import v_viewer_D3tTaMkNGJ from "/root/project/src/modules/ui/plugins/v-viewer.ts";
import vue_final_modal_qC2Yws9RRr from "/root/project/src/modules/ui/plugins/vue-final-modal.ts";
import error_handling_NFUsKBlkJE from "/root/project/src/modules/shared/plugins/error-handling.ts";
import unleash_0TVz8qFZ88 from "/root/project/src/modules/shared/plugins/unleash.ts";
import vue_query_7u2cTqaMOp from "/root/project/src/modules/shared/plugins/vue-query.ts";
export default [
  revive_payload_client_uEYVSyGRiP,
  unhead_wSJjb5xUH4,
  router_EBkMsVG4sK,
  payload_client_KXqPRAvysp,
  navigation_repaint_client_Nhd36xN4xS,
  check_outdated_build_client_WIZYXR03B8,
  chunk_reload_client_pOVEX5ZHM0,
  components_plugin_KR1HBZs4kY,
  prefetch_client_kSRMywzhhM,
  plugin_J0821rZplQ,
  _001_notivue_client_bE2FHKqHiu,
  plugin_6Ph1iQzNHk,
  formkitPlugin_pZqjah0RUG,
  plugin_cDYFCjuSwD,
  plugin_5WFR5umNIc,
  plugin_DVVyzEOKJp,
  switch_locale_path_ssr_e7GgmmIAzw,
  i18n_C8A1HtGs20,
  auth_aEAofnvHks,
  chartjs_n0Xc8XinLa,
  echarts_sPDVA7Eyaf,
  primevue_y8HUkHsSHD,
  v_viewer_D3tTaMkNGJ,
  vue_final_modal_qC2Yws9RRr,
  error_handling_NFUsKBlkJE,
  unleash_0TVz8qFZ88,
  vue_query_7u2cTqaMOp
]