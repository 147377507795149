import { useToast } from '@design-system/composables/toast'

export default defineNuxtPlugin((nuxtApp) => {
  const toast = useToast()

  nuxtApp.vueApp.config.errorHandler = async (error) => {
    console.error('errorHandler', error)

    if (window.newrelic && window.NREUM) {
      window.NREUM.noticeError(error)
    }

    toast.error(nuxtApp.$i18n.t('error.generalMessage'))

    return true
  }
})
