<script setup lang="ts">
import type { EventAnalysticTrack } from '@/event-analytics'
import { parseEventTrack } from '@/event-analytics'
import Icon from '@design-system/components/Icon/Icon.vue'
import Loading from '@design-system/components/Loading/Loading.vue'
import { icons } from '@design-system/icons'
import type { PropType } from 'vue'
import { useButtonClasses } from './composables/useButtonClasses'
import type {
  LButtonAutofocus,
  LButtonDisabled,
  LButtonFullWidth,
  LButtonIcon,
  LButtonIconClass,
  LButtonIconPosition,
  LButtonIconSize,
  LButtonLoading,
  LButtonRounded,
  LButtonSize,
  LButtonSquare,
  LButtonTo,
  LButtonType,
  LButtonVariant,
} from './types'
import { promisePending } from './utils'

const props = defineProps({
  variant: {
    type: String as PropType<LButtonVariant>,
    default: 'primary',
    validator: validateArray<LButtonVariant>([
      'primary',
      'secondary',
      'link',
      'green',
      'red',
      'outline-critical',
      'orange',
      'yellow',
      'purple',
      'blue',
    ]),
  },

  /**
   * any l-icon
   */
  icon: {
    type: String as PropType<LButtonIcon>,
    default: null,
    validator: validateArray<LButtonIcon>(icons as LButtonIcon[]),
  },

  /**
   * icon position
   */
  iconPosition: {
    type: String as PropType<LButtonIconPosition>,
    default: 'prefix',
    validator: validateArray<LButtonIconPosition>(['prefix', 'suffix']),
  },

  iconClass: {
    type: String as PropType<LButtonIconClass>,
    default: '',
  },

  iconSize: {
    type: Number as PropType<LButtonIconSize>,
    default: null,
  },

  /**
   * as native button type
   */
  type: {
    type: String as PropType<LButtonType>,
    default: 'button',
    validator: validateArray<LButtonType>(['button', 'submit', 'reset']),
  },

  /**
   * whether to set width to 100%
   */
  fullWidth: {
    type: Boolean as PropType<LButtonFullWidth>,
    default: false,
  },

  /**
   * whether to show loader inside button
   */
  loading: {
    type: Boolean as PropType<LButtonLoading>,
    default: false,
  },
  /**
   * whether the button is disabled
   */
  disabled: {
    type: Boolean as PropType<LButtonDisabled>,
    default: false,
  },

  /**
   * as native button autofocus
   */
  autofocus: {
    type: Boolean as PropType<LButtonAutofocus>,
    default: false,
  },

  size: {
    type: String as PropType<LButtonSize>,
    default: 'md',
  },

  square: {
    type: Boolean as PropType<LButtonSquare>,
    default: false,
  },

  rounded: {
    type: Boolean as PropType<LButtonRounded>,
    default: false,
  },

  to: {
    type: String as PropType<LButtonTo>,
    default: null,
  },

  event: {
    type: [String, Object] as PropType<string | EventAnalysticTrack>,
    default: undefined,
  },
})

const emit = defineEmits(['click', 'submit'])

// Inner loading
// FIXME: loading const may conflict with the loading prop
// eslint-disable-next-line vue/no-dupe-keys
const loading = ref(false)
watch(
  () => props.loading,
  (value) => (loading.value = value),
  { immediate: true },
)

const isDisabled = computed<boolean>(() => Boolean(props.disabled))
const isLoading = computed<boolean>(() => Boolean(loading.value))
const hasIcon = computed<boolean>(() => Boolean(props.icon))
const showIcon = computed<boolean>(() => Boolean(isLoading.value || hasIcon.value))
const iconSizeMap = {
  xs: 16,
  sm: 20,
  md: 24,
  lg: 24,
  xl: 24,
}
const iconSize = computed(() => props.iconSize ?? iconSizeMap[props.size])

const { wrapperClasses, textClasses, iconSpanClasses, loadingClasses, iconClasses } = useButtonClasses({
  ...toRefs(props),
  loading,
})

/**
 * The current instance.
 */
const instance = getCurrentInstance()
const eventsAnalytics = useEventsAnalytics()

// Wrap click events, then we can set `loading` state based on promises.
const setupClick = () => {
  if (!instance?.vnode.props) {
    return
  }

  if (instance.vnode.props.onClick) {
    const _onClick = instance.vnode.props.onClick
    instance.vnode.props.onClick = async (event: Event) => promisePending(_onClick(event), loading)
  }

  if (instance.vnode.props.onSubmit) {
    const _onSubmit = instance.vnode.props.onSubmit
    instance.vnode.props.onSubmit = async (event: Event) => promisePending(_onSubmit(event), loading)
  }
}
setupClick()

const onClick = (event: Event) => {
  if (props.event) {
    const event = parseEventTrack(props.event)
    eventsAnalytics.track(event.name, event.properties)
  } else {
    console.warn('Event is not being tracked!', props)
  }
  emit('click', event)
}
</script>

<template>
  <NuxtLink
    v-if="to && !isDisabled && !isLoading"
    :to="to"
    :class="wrapperClasses"
    :autofocus="Boolean(autofocus)"
    @click="onClick"
  >
    <!-- Icon -->
    <span v-if="showIcon" :class="iconSpanClasses">
      <Loading v-if="isLoading" :class="loadingClasses" :size="iconSize" />
      <Icon v-else-if="hasIcon" :icon="icon" :class="iconClasses" :size="iconSize" />
    </span>

    <span v-if="$slots.default" :class="textClasses">
      <slot />
    </span>
  </NuxtLink>
  <button
    v-else
    :class="wrapperClasses"
    :disabled="isDisabled || isLoading"
    :autofocus="Boolean(autofocus)"
    :type="type"
    @click="onClick"
  >
    <!-- Icon -->
    <span v-if="showIcon" :class="iconSpanClasses">
      <Loading v-if="isLoading" :class="loadingClasses" :size="iconSize" />
      <Icon v-else-if="hasIcon" :icon="icon" :class="iconClasses" :size="iconSize" />
    </span>

    <span v-if="$slots.default" :class="textClasses">
      <slot />
    </span>
  </button>
</template>
