import { useToast } from '@design-system/composables/toast'
import { createFetchError } from 'ofetch'
import { joinURL } from 'ufo'
import { parseHeaders } from '../utils/parseHeaders'
import { sanitizeFileName } from '../utils/sanitizeFileName'

import type { LResponse } from '@shared'
import type { UseQueryOptions } from '@tanstack/vue-query'
import type { MaybeRef } from '@vueuse/core'
import type { FetchError } from 'ofetch'
import type { Attachment, CreateAttachment, CreateAttachmentOptions } from '../types'

export function getAttachmentQueryOptions(id: MaybeRef<string>) {
  const queryOptions: UseQueryOptions<LResponse<Attachment>, FetchError> = {
    queryKey: ['attachments', unref(id)],
    queryFn: () =>
      fetchAttachments<LResponse<Attachment>>(`/attachments/${unref(id)}`, {
        onResponse: async ({ request, response }) => {
          if (response.status === 204) {
            throw createFetchError(request, undefined, response)
          }
        },
      }),
    // Attachments are only available for 5 min, after that we need to authorize again.
    staleTime: 5 * 1000 * 60,
    cacheTime: 5 * 1000 * 60,
  }

  return queryOptions
}

export function useAttachment(id: MaybeRef<string>) {
  const queryOptions = getAttachmentQueryOptions(id)
  return useQuery(queryOptions)
}

export function useDownloadAttachment<T extends Record<string, unknown> = Record<string, unknown>>(
  callback: (item: T) => { id: string; attachmentId: string } = (item) => ({
    id: item.id,
    attachmentId: item.attachmentId,
  }),
) {
  // TOAST & I18N
  const { t } = useI18n()
  const toast = useToast()

  // ACTIVE DOWNLOAD LIST
  const {
    isActive: isDownloading,
    addToList: addToDownloads,
    removeFromList: removeFromDownloads,
  } = useActiveList((item: T) => callback(item).id)

  // DOWNLOAD FUNCTION
  const download = async (item: T) => {
    addToDownloads(item)

    try {
      const attachment = await getAttachment(callback(item).attachmentId)
      window.open(attachment.presignedUrl, '_blank')
    } catch (e) {
      console.error(e)
      toast.error(t('general.submitError'))
    } finally {
      removeFromDownloads(item)
    }
  }

  return {
    isDownloading,
    download,
  }
}

export function getAttachment(id: MaybeRef<string>) {
  return fetchAttachments<LResponse<Attachment>>(`/attachments/${unref(id)}`).then((response) => response.data)
}

export function createAttachment(body: CreateAttachment, options?: CreateAttachmentOptions) {
  const { apiURL, attachmentsAPI } = useRuntimeConfig().app
  const url = joinURL(apiURL, attachmentsAPI, '/attachments')
  const {
    headers = {},
    onProgress = () => {
      //
    },
  } = options ?? {}
  const payload = createPayload()

  const { token } = useAuth()

  return new Promise<Response>((resolve, reject) => {
    const xhr = new XMLHttpRequest()

    xhr.onload = () => {
      const headers = parseHeaders(xhr.getAllResponseHeaders() || '')

      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(
          new Response(xhr.response ?? xhr.responseText, {
            status: xhr.status,
            statusText: xhr.statusText,
            headers,
          }),
        )
      } else {
        reject(xhr.statusText)
      }
    }

    xhr.onerror = () => {
      reject(xhr.statusText)
    }

    xhr.ontimeout = () => {
      reject(xhr.statusText)
    }

    xhr.open('POST', url, true)

    xhr.setRequestHeader('Authorization', `Bearer ${token.value}`)
    Object.keys(headers).forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      xhr.setRequestHeader(key, headers[key])
    })

    if (xhr.upload) {
      xhr.upload.onprogress = onProgress
    }

    xhr.send(payload)
  }).then((response) => response.json() as Promise<LResponse<Attachment>>)

  function createPayload() {
    const payload = new FormData()
    payload.append('file', body.file.file, sanitizeFileName(body.file.name))
    payload.append('type', body.type)

    if (body.isPublic !== undefined) {
      payload.append('isPublic', String(body.isPublic))
    }

    return payload
  }
}

export function deleteAttachment(id: MaybeRef<string>) {
  return fetchAttachments<undefined>(`/attachments/${unref(id)}`, { method: 'DELETE' })
}
