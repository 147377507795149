import type { PushPromiseReturn } from 'notivue'
import { push } from 'notivue'

export class Toast {
  success(message: string): void {
    push.success(message)
  }

  info(message: string): void {
    push.info(message)
  }

  warning(message: string): void {
    push.warning(message)
  }

  error(message: string): void {
    push.error(message)
  }

  promise(message: string): PushPromiseReturn {
    return push.promise(message)
  }
}

export function useToast() {
  return new Toast()
}
