<script lang="ts" setup>
/* eslint-disable vue/no-v-html */

import { icons, type Icon } from '@design-system/icons'

const props = defineProps({
  icon: {
    type: String as PropType<Icon>,
    required: true,
    validator: function (value: Icon) {
      return icons.includes(value)
    },
  },
  filled: {
    type: Boolean,
    default: true,
    required: false,
  },
  size: {
    type: Number,
    default: 24,
  },
})

const rawIconImport = await import(`@design-system/assets/icons/${props.icon}.svg?raw`)
const rawIcon = rawIconImport.default
const hasStroke = ref(false)

if (rawIcon.includes('stroke')) {
  hasStroke.value = true
}
</script>

<template>
  <span
    class="nuxt-icon"
    :class="{ 'nuxt-icon--fill': !filled, 'nuxt-icon--stroke': hasStroke && !filled }"
    :style="{ fontSize: size + 'px' }"
    v-html="rawIcon"
  />
</template>

<style>
.nuxt-icon svg {
  width: 1em;
  height: 1em;
  vertical-align: middle;
}

/* stylelint-disable */
.nuxt-icon.nuxt-icon--fill,
.nuxt-icon.nuxt-icon--fill * {
  fill: currentColor !important;
}

.nuxt-icon.nuxt-icon--stroke,
.nuxt-icon.nuxt-icon--stroke * {
  stroke: currentColor !important;
}
/* stylelint-enable */
</style>
